@import "variables";
// includes
@import "includes/functions";
@import "includes/normalize";
@import "includes/45p";
@import "includes/fonts";
@import "includes/feed";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
    @media screen and (max-width: 1600px){
        font-size: 15px;
    }
    @media screen and (max-width: 1400px){
        font-size: 13px;
    }
}

body {
    background-color: $pink;
    background-image: url('../img/bg.png'), linear-gradient(to bottom,$pink,$purple);
    background-size: cover;
    background-attachment: fixed, static;
    background-repeat: no-repeat;
    background-position: top center;
    color: $beige;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rems(16);
    line-height: 1.2;
    overflow: hidden;
}

a{
    color: inherit;
    @include hover{
        color: $primary-color;
    }
}

.container{
    max-width: rems(1260);
}

h2{
    text-transform: uppercase;
    font-weight: 300;
    font-size: rems(200);
    color: $white;
    @media screen and (max-width: 900px){
        font-size: rems(80);
        text-align: center !important;
    }
}

.btn{
    display: inline-block;
    font-size: rems(26);
    text-transform: uppercase;
    padding: .4em 2em .3em;
    color: $beige;
    background-color: transparent;
    border: 2px solid $primary-color;
    @include hover{
        color: $pink;
        background-color: $primary-color;
    }
}

#header{
    position: fixed;
    background-image: linear-gradient(to bottom, #2E1F1F, #2E1F1F00);
    background-size: cover;
    color: $beige;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    .container{
        max-width: rems(1800);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    h1{
        max-width: 80%;
    }
    .nav{
        flex: 1;
        display: flex;
        justify-content: space-around;
        text-transform: uppercase;
        font-size: rems(30);
        @media screen and (max-width: 900px){
            display: block;
            text-align: right;
            top: 0;
            z-index: 4;
            right: rems(-500);
            background-color: $pink;
            position: absolute;
            padding: rems(50) rems(20) rems(20);
            transition: right .3s;
            &.open{
                right: 0;
            }
        }
        
        @media screen and (max-width: 400px){
            font-size: rems(30);
        }
        li{
            margin-bottom: .5em;
        }
        a{
            border-bottom: 2px solid transparent;
            @include hover{
                color: $gold;
                border-color: $white;
            }
        }
    }

    .menu-toggle{
        display: none;
    }
    @media screen and (max-width: 900px){
        .menu-toggle{
            display: block;
            position: absolute;
            width: rems(40);
            height: rems(40);
            top: rems(10);
            right: rems(10);
            z-index: 10;
            &::before, &::after{
                position: absolute;
                transition: .3s all;
                height: rems(4);
                width: 100%;
                content: '';
                left: 50%;
                transform-origin: center;
                transform: translateX(-50%);
                background-color: $gold;
            }
            &:after{
                top: 30%;
            }
            &:before{
                top: 70%;
            }
            &.open{
                &::after{
                    transform: translateX(-50%) rotate(-45deg);
                    top: 50%;
                }
                &::before{
                    transform: translateX(-50%) rotate(45deg);
                    top: 50%;
                }
            }
        }
    }
}

#hero{
    background-image: url('../img/hero.png');
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    .social{
        color: $beige;
        width: rems(40);
        position: absolute;
        right: rems(20);
        top:50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-size: rems(27);
        text-align: center;
    }

    .btn{
        position: absolute;
        bottom: rems(40);
        left: 50%;
        transform: translateX(-50%);
        background-color: $pink;
        @include hover{
            background-color: $gold;
        }
    }
}

#music{
    padding: rems(120) 0 0;
    position: relative;
    @media screen and (max-width: 900px){
        padding-top: rems(200);
    }
    .graphic{
        position: absolute;
        top: rems(40);
        right: rems(40);
        width: 100%;
        max-width: rems(200);
        @media screen and (max-width: 900px){
            max-width: rems(140);
        }
    }
    .item{
        display: flex;
        text-align: center;
        align-items: center;
        margin-bottom: rems(100);
        >div{
            flex: 1;
        }
        .info{
            margin-left: rems(30);
        }
        p{
            font-size: rems(30);
            text-transform: uppercase;
            margin: 0;
        }
        .title{
            font-family: $header-font;
            font-size: rems(80);
            text-transform: lowercase;
            margin: rems(-30) 0 0;
        }
        &+.item{
            margin-top: rems(30);
        }
        @media screen and (min-width: 901px){
            &:nth-child(even){
                flex-direction: row-reverse;
                .info{
                    margin-right: rems(30);
                }
            }
        }
        @media screen and (max-width: 900px){
            flex-direction: column;
            .info{
                margin: rems(30) 0 0;
            }
        }
    }
}

#videos{
    padding: rems(40) 0 rems(100);
    h2{ 
        text-align: center;
        color: $primary-color;
    }

    .media-responsive{
        background-color: $black;
    }

    #video-carousel{
        margin-top: rems(40);
        text-align: center;
        font-size: rems(24);
    }
}

#about{
    // background-color: #fefbf8;
    // color: $black;
    background-image: linear-gradient(to top, #e48474, #e4847400);
    background-size: 100% rems(500);
    background-position: bottom center;
    background-repeat: no-repeat;
    text-align: center;
    .container{
        max-width: rems(1000);
    }
    h2{
        color: $primary-color;
        text-align: center;
        
        @media screen and (max-width: 900px){
            margin-top: 1em;
        }
    }
    .graphic{
        width: rems(160);
    }
    p{
        font-size: rems(24);
        line-height: 1.5;
        color: $white;

    }
    .more{
        display: none;
        p:first-child{
            margin-top: 0;
        }
    }
    .btn-wrap{
        padding: rems(60) 0 rems(100);
        text-align: center;
    }
}

#tour{
    background-image: url('../img/tour-bg.png');
    background-size: cover;
    background-position: center;
    padding: rems(200) 0 rems(120);
    
    @media screen and (max-width: 900px){
        padding: rems(50) 0;
    }
    h2{
        text-align: center;
        color: $beige;
        margin-bottom: rems(80);
        margin-top: -.5em;
        text-shadow: 0 0 rems(5) #000A;
        @media screen and (max-width: 900px){
            margin-bottom: rems(40);
        }
    }
    .container{
        padding: rems(10) rems(80) rems(80);
        max-width: rems(1500);
        background-color: #654B58AA;
        border: 1px solid $gold;
        @media screen and (max-width: 900px){
            padding: rems(10) rems(10) rems(30);
        }
    }

    .no-events{
        text-align: center;
        font-size: rems(30);
        text-transform: uppercase;
    }

    .event{
        text-transform: uppercase;
        font-size: rems(30);
        &+.event{
            margin-top: rems(30);
        }
        
        @media screen and (max-width: 900px){
            text-align: center;
            .tickets{
                margin-top: rems(10);
            }
        }
        @media screen and (min-width: 901px){
            display: flex;
            align-items: center;
            >div{
                flex: 1;
                &.date{
                    flex: .5;
                }
            }
            .location{
                margin-right: rems(30);
            }

            .tickets{
                text-align: right;
            }
        } 
    }

    #dates:not(.seeall) .event:nth-child(n+7){display: none;}

    .tickets-link, .tour-more{
        display: inline-block;
        border: 2px solid $gold;
        font-weight: bold;
        padding: .5em 2em;
        text-transform: uppercase;
        font-size: rems(26);
        color: $beige;
    }

    .tour-more-wrap{
        margin-top: rems(60);
        text-align: center;
    }
}

#community{
    background-color: $white;
    padding: rems(30) 0 0;
    .container{
        max-width: rems(800);
        img{
            display: block;
        }
    }
}

#instagram{
    padding: rems(120) 0 rems(120);
    background-image: linear-gradient(to bottom, #e48474, #e4847400);
    background-size: 100% rems(500);
    background-position: top center;
    background-repeat: no-repeat;
    h2{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: rems(30);
        color: $beige;
        font-size: rems(200);
        font-family: $header-font;
        text-transform: lowercase;
        i{
            font-size: .5em;
            padding-right: .5em;
        }
        @media screen and (max-width: 900px){
            font-size: rems(100);
        }
    }
}

#signup{
    .graphic{
        width: rems(160);
        display: block;
        margin: 0 auto rems(120)
    }
    h2{
        text-align: center;
        color: $beige;
        margin-top: -.5em;
        text-shadow: 0 0 rems(5) #000A;
        @media screen and (max-width: 900px){
            margin-bottom: rems(40);
        }
    }
    .box{
        width: 100%;
        max-width: rems(1500);
        margin: 0 auto;
        background-color: #AD5A5DAA;
        border: 1px solid $gold;
        p{
            text-align: center;
            font-size: rems(30);
            text-transform: uppercase;
        }
    }
    
    .top{
        padding: 0 rems(30) rems(30);
        p{
            margin-top: -.5em;
        }
        form{
            text-align: center;
            input{
                font-size: rems(30);
                border: 2px solid $gold;
                width: 100%;
                max-width: rems(900);
                margin: 0 auto 1em;
                padding: .4em .4em .3em;
                display: block;
                &::placeholder{
                    color: $beige;
                }
            }
        }
    }

    .phone{
        padding: 1em 1em .7em;
        background-color: $gold;
        color: $purple;
        p{
            margin: 0;
        }
    }
}

#footer{
    text-align: center;
    padding: rems(40) 0 rems(60);
    .container{
        max-width: rems(900);
    }
    .contacts{
        margin: rems(40) auto;
        p{
            margin: 0 auto;
            font-size: rems(24);
        }
    }
    .copyright{
        font-size: rems(10);
        text-transform: uppercase;
    }
}

.popup{
    position: relative;
    margin: 0 auto;
    max-width: rems(1300);
    width: 100%;
    padding: rems(40) 0;
    a{
        &:hover, &:active, &:focus{
            opacity: .9;
        }
    }
}