@font-face {
    font-family: 'The Barstain';
    src: url('../font/TheBarstain-Regular.woff2') format('woff2'),
        url('../font/TheBarstain-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bayshore';
    src: url('../font/Bayshore.woff2') format('woff2'),
        url('../font/Bayshore.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}