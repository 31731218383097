//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #fff;
$beige: #E2D4B9;
$brown: #453626;
$pink: #AD5A5D;
$purple: #624A58;
$gold: #CF9341;
$primary-color: $gold;
$gray: #656565;
 
//Fonts
$primary-font: 'The Barstain', sans-serif;
$header-font: 'Bayshore', sans-serif
